<ng-container *transloco="let t;">
  <div class="container-fluid">
    <div class="row g-0 mb-2">
      <div class="col-11">
        <h6 class="section-title" [id]="id || title">{{title}}</h6>
      </div>
      <div class="col-1">
        @if (switchRef) {
          <ng-container [ngTemplateOutlet]="switchRef"></ng-container>
        }
      </div>
    </div>


    @if (subtitle) {
      <div class="text-muted mt-2" [innerHTML]="subtitle | safeHtml"></div>
    }
  </div>
</ng-container>
